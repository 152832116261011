export default function slugRedirect({ app: { router }, route, $auth, redirect }) {
  const user = $auth?.user;
  const slug = user?.workspace?.slug;

  if (!route.name || route.name.startsWith('t-tenantSlug-p')) return null;
  if (!$auth?.loggedIn || !slug || !('tenantSlug' in route.params)) return null;

  if (route.params.tenantSlug !== slug) {
    const newRoute = router.resolve({
      ...route, params: { ...route.params, tenantSlug: slug },
    });

    return redirect(newRoute);
  }

  return null;
}
